<template>
  <div id="inspection-form">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>菜单管理</span>
      </div>
      <div class="content">
        <div class="query-form">
          <el-form class="form" ref="form" :inline="true" :model="requestParam">
            <!-- <el-form-item label="变配电站：">
              <el-select v-model="requestParam.stationId" :popper-append-to-body="false" clearable placeholder="请选择电站">
                <el-option v-for="item in stationData" :key="item.id" :label="item.stationName"
                  :value="item.id"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="菜单名称：" class="myDialogForm">
              <el-input v-model="requestParam.menuName" placeholder="请输入菜单名称" clearable>
              </el-input>
            </el-form-item>
          </el-form>
          <div class="query-button">
            <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>
            <el-button type="primary" :class="isStringInArray('btnNewMenuAdministration') ? '' : 'btnShowAuthority'" icon="el-icon-plus" @click="createInspection">新建菜单</el-button>
          </div>
        </div>

        <el-table v-loading='dataLoading' element-loading-text="加载中" element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.3)" :cell-style="{ color: '#AED6FF', fontSize: '12px' }"
          :header-cell-style="{ color: '#e8f4ff', fontSize: '14px' }" class="table" :data="tableData" :default-expand-all="false"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" row-key="id">
          <!-- <el-table-column type="selection" width="55">
        <template v-slot:header>
          <el-checkbox v-model="checkedAll" @change="handleCheckedAllChange"></el-checkbox>
        </template>
        </el-table-column> -->
          <div slot="empty">
            <div v-if="tableData.length == 0">
              <div class="empty-data-view">
                <div class="tableText">
                  {{ dataLoading ? '' : '暂无数据' }}
                </div>
              </div>
            </div>
          </div>
          <el-table-column align="center" type="index" width="50">
            <template slot-scope="scope">
              <el-radio @change="tableRadio(scope.row)" v-model="selected" :label="scope.row.id">
                {{ '' }}
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column align="center" label="序号" type="index" :index="getIndex" width="60px">
          </el-table-column>
          <el-table-column prop="menuName" label="菜单名称" width="300px">
          </el-table-column>
          <el-table-column align="center" prop="code" label="菜单编码">
          </el-table-column>
          <el-table-column align="center" prop="url" label="菜单路径">
          </el-table-column>
          <el-table-column align="center" prop="url" label="菜单类型">
            <template slot-scope="scope">
              {{ scope.row.type==1? '菜单':'按钮' }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="sort" label="排序">
          </el-table-column>
          <!-- <el-table-column align="center" prop="createBy" label="创建人">
          </el-table-column> -->
          <el-table-column align="center" prop="createTime" label="创建时间">
          </el-table-column>
          <el-table-column align="center" prop="data" fixed="right" label="操作" width="180px">
            <template slot-scope="scope">
              <el-button :class="isStringInArray('btnEditMenuAdministration') ? '' : 'btnShowAuthority'" @click="editDialog(scope.row)" type="text">编辑</el-button>
              <span :class="isStringInArray('btnEditMenuAdministration') ? '' : 'btnShowAuthority'" style="color: #409EFF;">|</span>
              <el-button :class="isStringInArray('btnDeleteMenuAdministration') ? '' : 'btnShowAuthority'" @click="deleteDialog(scope.row)" type="text">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model.number="inputValue" size="mini" maxlength="2" class="pagination-input" @input="handleEdit"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog @close="closeDialog('form')" :title="dialogTotalType == 0 ? '新建菜单' : '编辑菜单'" :visible.sync="dialogVisible"
      width="751px">
      <div class="dialog-content">
        <div class="form-title"></div>
        <el-form class="" ref="form" :rules="rules" :model="form" label-width="100px">
          <el-form-item label="上级菜单:"  class="myDialogForm" v-if="dialogTotalType == 0">
            <el-input v-model="menuName" :readonly="true"  class="myFormItem" placeholder="请输入菜单名称"></el-input>
          </el-form-item>
          <el-form-item label="菜单类型:" prop="type" class="myDialogForm" >
            <!-- <el-input v-model.trim="form.menuName" class="myFormItem" placeholder="请输入菜单名称"></el-input> -->
            <el-radio-group v-model="form.type">
                  <el-radio :label="1">菜单</el-radio>
                  <el-radio :label="2">按钮</el-radio>
                  <!-- <el-radio v-if="menuName" :label="2">按钮</el-radio> -->
                </el-radio-group>
          </el-form-item>
          <el-form-item label="菜单名称:" prop="menuName" class="myDialogForm">
            <el-input v-model.trim="form.menuName" class="myFormItem" placeholder="请输入菜单名称"></el-input>
          </el-form-item>
          <el-form-item label="菜单编码:" prop="code" class="myDialogForm">
            <el-input v-model.trim="form.code" class="myFormItem" placeholder="请输入菜单编码"></el-input>
          </el-form-item>
          <el-form-item label="排序:" prop="sort" class="myDialogForm">
            <el-input v-model.number="form.sort" class="myFormItem" placeholder="请输入排序"></el-input>
          </el-form-item>
          <el-form-item v-if="form.type==1" label="菜单路径:" prop="url" class="myDialogForm">
            <el-input v-model.trim="form.url" class="myFormItem" placeholder="请输入菜单路径"></el-input>
          </el-form-item>


        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addDialogVisible('form')">确 定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="delete">确定要删除该菜单吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteInspectionForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
// import { inspectionFormApi } from "@/api/inspectionForm.js";
import { menuList, menuAdd, menuUpdate, menuDelete } from "@/api/eios.js"

export default {
  name: "",
  data() {
    return {
      menuName:'',
      dataLoading: false,
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        menuName: null,
      },
      form: {
        menuName: '',
        code: '',
        url: '',
        sort:'',
        parentId: '',
        type:'',
      },
      rules: {
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' },
        ],
        type: [
          { required: true, message: '请选择菜单类型', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '请选择菜单编码', trigger: 'blur' },
        ],
        url: [
          { required: true, message: '请输入菜单路径', trigger: 'blur' },
        ],
        // provinceId: [
        //   { required: true, message: '请选择省', trigger: 'change' }
        // ],

      },
      total: 0,
      inputValue: 1,
      viewVisible: false,
      deleteVisible: false,
      dialogVisible: false,
      dialogVisible2: false,
      detailsData: {},
      id: "",
      inspectionItem: [],
      stationData: [],
      dialogTotalType: 0,
      checkedAll: null,
      address: [],
      addressCityId: [],
      addressAreaId: [],
      areaId: [],
      UnitNameList: [],
      selected: '',
      parentId: 0,
      cascaderProps: {
        children: 'regionEntityList',
        value: 'id',
        label: 'shortName'
      },
      authorityListBtn:[],
    };
  },
  created() {
    this.$store.commit('increment', 'Eios控制面板')
    this.$store.commit('selectChild', '菜单管理')
    this.$store.commit("selectChildren", "");
    this.dataLoading = false
    // this.getList()
  },

  mounted() {
    this.queryOperation()
    this.authorityListBtn=sessionStorage.getItem('roleInfo')
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
     deep:true,
      immediate: true, // 第一次改变就执行
    },
  },
  methods: {
     //按钮权限
     isStringInArray(str) {
      if (this.authorityListBtn) {
        let list = this.authorityListBtn.includes(str);
        return list
      } else {
        return false
      }
    },

    //table选中
    handleCheckedAllChange(val) {
      console.log(val);
    },

    isOverflow(content) {
      const lineHeight = 16;
      const numLines = Math.ceil(content.length / 42); // 每行大概显示42个字符
      // console.log("====>",numLines);
      return numLines >= 3;
    },
    //序号
    getIndex(index) {
      return (this.requestParam.pageNum - 1) * this.requestParam.pageSize + index + 1;
    },
    closeDialog(form) {
      this.form = this.$options.data().form
      this.$refs[form].resetFields();
      
    },
    //新增
    addDialogVisible(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let obj = {
            ...this.form,
            parentId: this.parentId
          }
          // console.log(objUpdate);
          let func = this.dialogTotalType === 0 ? menuAdd(obj) : menuUpdate(this.form)
          func.then(res => {
            if (res.code === 200) {
              this.dialogVisible = false
              this.$message({
                message: res.message,
                type: 'success',
                duration: 3000,
                customClass: "messageText",
              });
              this.queryOperation()
            } else {
              this.$message({
                message: res.message,
                type: 'error',
                duration: 3000,
                customClass: "messageText",
              });
            }
          })


        } else {
          return false
        }


      });
    },
    //新增弹窗打开
    createInspection() {
      this.dialogVisible = true;

      this.dialogTotalType = 0
    },
    editDialog(el) {
      this.id = el.id
      this.dialogVisible = true
      this.form = Object.assign({}, el);
      // this.parentId=el.parentId
      this.dialogTotalType = 1

    },

    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      if (this.areaId.length == 3) {
        this.requestParam.areaId = this.areaId[2]
      } else {
        this.requestParam.areaId = null
      }
      if (this.areaId.length >= 2) {
        this.requestParam.cityId = this.areaId[1]
      } else {
        this.requestParam.cityId = null
      }
      if (this.areaId.length >= 1) {
        this.requestParam.provinceId = this.areaId[0]
      } else {
        this.requestParam.provinceId = null
      }
      console.log(this.areaId.le);
      this.queryOperation();
    },

    queryOperation() {
      this.dataLoading = true
      menuList(this.requestParam).then(res => {
        this.dataLoading = false
        if (res.code === 200) {
          // 遍历输入的数据
          // console.log('111111111',res.data);
          //层级
          //  this.transformData(res.data)
          this.tableData = res.data
          //  console.log(this.tableData);

          this.total = res.total
          console.log("2222222222222");
        }
      })


    },
    transformData(data) {
      const map = {};
      const result = [];
      // 创建一个映射，方便通过 id 查找节点
      data.forEach(item => {
        map[item.id] = { ...item, children: [] };
      });
      // console.log('===>',map);
      // 将子节点添加到父节点的 children 中
      data.forEach(item => {
        if (item.parentId !== 0) {
          // console.log('1111111111111111111',map[item.parentId]);
          if (map[item.parentId]) {
            map[item.parentId].children.push(map[item.id]);
          } else {
            // result.push(map[item.id]);
          }
        } else {
          result.push(map[item.id]);
        }
      });

      this.tableData = result;
    },
    handleEdit(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryOperation();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryOperation();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryOperation();
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryOperation();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryOperation();
    },


    //删除
    deleteInspectionForm() {
      menuDelete(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryOperation();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    // //选择省
    // provinceIdChange(el){
    //   console.log(this.address,el);
    //   const selectedOption = this.address.find(item => item.id == el);;
    //   console.log(selectedOption);
    //   this.addressCityId=selectedOption.regionEntityList

    // },
    // //选择市
    // cityIdChange(el){
    //   const selectedOption = this.addressCityId.find(item => item.id == el);;
    //   console.log(selectedOption);
    //   this.addressAreaId=selectedOption.regionEntityList
    //   console.log( this.addressAreaId);

    // },

    tableRadio(el) {
      console.log(el);
      console.log(this.selected);
      if (this.selected) {
        this.parentId = el.id
        this.menuName=el.menuName
      } else {
        this.parentId = 0

      }
    }
  },
  watch: {
    // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
    "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
  },
};
</script>
<style scoped>
#inspection-form {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left top;
  background-size: cover;
  font-size: 16px;
}

.main {
  width: 100%;
  padding: 20px 26px 28px 25px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

/* 查询表单 */
.query-form {
  padding: 16px 20px;
  background-color: #061b45;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.query-button {
  display: flex;
  /* justify-content: space-between; */
}

.query-button ::v-deep .el-button {
  height: 35px;
  padding: 0 20px;
  background-color: #176ad2;
  text-align: center;
  line-height: 35px;
  font-size: 12px;
  color: #e8f4ff;
  border: 0;
}

.form ::v-deep .el-form-item {
  margin: 0 16px 12px 0;
  /* width: 100%; */
}

.form ::v-deep.el-form-item__label {
  width: 61px;
  padding-right: 0;
  color: #e8f4ff;
  font-size: 12px;
  text-align: left;
}

/* input */
.form ::v-deep .el-input .el-input__inner {
  height: 35px;
  width: 180px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

::v-deep .el-input .el-input__inner {
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.form ::v-deep .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

::v-deep .el-input input::placeholder {
  font-size: 12px;
  color: #6180a8;
}

::v-deep .el-textarea .el-textarea__inner::placeholder {
  font-size: 12px;
  color: #6180a8;
}

/* 下拉选择框 */
.el-select>>>.el-select-dropdown {
  background: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.hover {
  background: #041c4c;
  color: #fff;
}

::v-deep .el-select-dropdown__item {
  color: #aed6ff;
}

::v-deep .el-select-dropdown__item.selected {
  color: #fff;
}

.el-select ::placeholder {
  color: #6180a8;
}

.el-select {
  width: 100%;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table ::v-deep thead th {
  background-color: #09224b;
  color: #e8f4ff;
  font-size: 14px;
}

.table ::v-deep tbody tr {
  font-size: 12px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

/* 暂无数据 */
::v-deep .el-table__body-wrapper {
  background: #071734;
}

::v-deep .el-table__empty-text {
  color: aliceblue;
  line-height: 150px;
}

.response-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.operate span {
  margin: 0 3px;
}

.tooltip {
  text-align: center;
}

/* 分页 */
.pagination {
  margin-top: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination ::v-deep .el-pagination {
  padding-left: 0;
}

.home-page,
.tail-page {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  box-sizing: border-box;
  font-family: "Arial", sans-serif;
  color: #92b5da;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
}

.home-page:hover,
.tail-page:hover {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.home-page:checked,
.tail-page:checked {
  background-color: #176ad2;
  box-sizing: border-box;
  color: #ffffff;
}

.el-pagination>>>.btn-prev,
.el-pagination>>>.btn-next {
  width: 60px;
  height: 30px;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  margin-right: 5px;
  line-height: 30px;
  padding: 0;
  text-align: center;
}

.el-pagination>>>.el-pager li {
  width: 35px;
  height: 30px;
  background: rgba(255, 255, 255, 0);
  color: #d7d7d7;
  border-radius: 2px;
  border: 1px solid #0b2653;
  margin-right: 5px;
}

.jump-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-input {
  height: 30px;
  width: 35px;
  margin: 0 10px;
}

.pagination-input>>>.el-input__inner {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #0b2653;
  padding: 0;
  text-align: center;
  color: #92b5da;
}

.pagination ::v-deep.el-select .el-input__inner {
  height: 30px;

  color: #92b5da;
  border: 1px solid #0b2653;
  background-color: rgba(255, 255, 255, 0);
}

.pagination ::v-deep .el-pagination__sizes {
  margin-right: 0;
}

.jump-pagination .text {
  color: #92b5da;
  line-height: 30px;
}

.confirm {
  width: 60px;
  height: 30px;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0);
  background-color: #176ad2;
  font-family: "微软雅黑", sans-serif;
  color: #d7d7d7;
  text-align: center;
  line-height: 30px;
  padding: 0;
  margin-left: 5px;
}

/* 弹窗 */
::v-deep .el-dialog {
  background-color: #071734;
}

::v-deep .el-dialog .el-dialog__header {
  background-color: #05153a;
  border-bottom: 1px solid #09295b;
}

::v-deep .el-dialog__title {
  font-size: 16px;
  color: #c9d4f1;
}

::v-deep .el-dialog__body {
  padding: 0;
}

::v-deep .el-dialog__footer {
  padding-top: 0;
}

.form-title {
  font-family: "Arial Bold", "Arial Normal", "Arial", sans-serif;
  font-weight: 700;
  color: #c9d4f1;
  font-size: 16px;
  margin-bottom: 16px;
}

.dialog-content {
  margin: 18px 50px 0 47px;
}

.details-form ::v-deep.el-form-item__label {
  width: 69px;
  color: #c9d4f1;
  font-size: 12px;
  text-align: right;
  margin-right: 28px;
}

.details-form ::v-deep .el-input .el-input__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form ::v-deep .el-textarea .el-textarea__inner {
  width: 447px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.interval {
  width: 654px;
  height: 1px;
  background-color: #06224e;
  margin-bottom: 16px;
}

.details-form .default-inspection ::v-deep .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: rgba(255, 255, 255, 0);
}

.details-form .selected-inspection::v-deep .el-input .el-input__inner {
  width: 651px;
  color: #c9d4f1;
  font-size: 12px;
  border: 1px solid #06224e;
  background-color: #041c4c;
}

.default-inspection ::v-deep .el-select-dropdown__item {
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  border-bottom: 1px solid #06224e;
  box-sizing: border-box;
  font-size: 12px;
  color: #aed6ff;
  font-weight: 300;
}

.details-form ::v-deep .el-select-dropdown__item {

  display: flex;
  justify-content: space-between;
}

::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
  top: 0;
  border-bottom-color: #041c4c;
}

.delete {
  font-size: 16px;
  font-weight: 700;
  color: #c9d4f1;
  margin: 42px 0 64px 39px;
}

.dialog-footer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dialog-footer button {
  width: 106px;
  height: 36px;
  background: #2142a1;
  border: 0;
  color: #ffffff;
  font-size: 13px;
}

.tooltip {
  max-width: 600px;
  /* 设置最大宽度 */
  line-height: 20px;
}


::v-deep .el-icon-arrow-right:before {
  color: #aed6ff;
  font-size: 16px;
}

::v-deep .el-textarea {
  width: 100%;
}

.place {
  width: 100%;
}

.myDialogForm>>>.el-form-item__label {
  color: #e8f4ff;
}

.myFormItem>>>.el-input__inner {

  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;

}

.myFormItem>>>.el-textarea__inner {

  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;


}

/* tableLoading字体设置 */
::v-deep .el-icon-loading {
  font-size: 18px;

}

::v-deep .el-loading-text {
  font-size: 18px;

}

.tableText {
  font-size: 16px;
  color: #aed6ff;

}
::v-deep .el-radio__label{
  color: #aed6ff;
}
</style>

